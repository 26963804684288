s<template>
  <v-dialog v-model="dialogActive" persistent width="600">
    <template v-slot:activator="{ on }">
      <v-list-item v-on="on">
        <v-list-item-avatar>
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-icon v-on="on" :color="statusColor">{{ statusIcon }}</v-icon>
            </template>
            <span>{{ statusText }}</span>
          </v-tooltip>
        </v-list-item-avatar>
        <v-list-item-content>
          <v-list-item-title>{{ title }}</v-list-item-title>
          <v-list-item-subtitle>{{ detail }}</v-list-item-subtitle>
        </v-list-item-content>
        <v-list-item-action v-if="form != null && form !== ''">
          <v-tooltip top>
            <template v-slot:activator="{ on }">
              <v-btn v-on="on" :to="'/forms/admin/' + form" icon style="margin-top:-6px">
                <v-icon>fal fa-external-link</v-icon>
              </v-btn>
            </template>
            <span>View/Edit this form</span>
          </v-tooltip>
        </v-list-item-action>
      </v-list-item>
    </template>
    <v-card>
      <v-toolbar>
        <v-toolbar-title>Edit Checklist Item</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="dialogActive = false">
          <v-icon>fas fa-times</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text style="margin-top:1em">
        <v-text-field v-model="title" label="Item Label" outlined hide-details></v-text-field>
        <v-switch v-model="itemActive" :label="'Checklist Item is ' + (itemActive ? 'Active' : 'Inactive; Will not show up in the list for any student')" hide-details style="margin:1em 0"></v-switch>
        <v-textarea v-model="detail" :disabled="!itemActive" label="Description" outlined hide-details></v-textarea>
        <h4 style="margin-top:1em">Who should see this checklist item? Leave blank for all.</h4>
        <v-row style="margin-bottom:1em">
          <v-col>
            <v-select v-model="classLevel" :items="classLevels" :disabled="!itemActive" label="Class Level" multiple outlined hide-details></v-select>
          </v-col>
          <v-col>
            <v-select v-model="studentType" :items="studentTypes" :disabled="!itemActive" label="Student Type" multiple outlined hide-details></v-select>
          </v-col>
        </v-row>
        <v-select v-if="itemIndex > 0" v-model="prereqs" :items="prevItems" :disabled="!itemActive" label="Pre-requisites (must be completed before this one is enabled)" multiple outlined chips small-chips deletable-chips hide-details style="margin-bottom:1em"></v-select>
        <v-autocomplete v-if="formSearchActive" v-model="form" :items="forms" :search-input.sync="formSearch" item-value="_id" item-text="name" outlined hide-details>
          <template v-slot:append-outer>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon style="margin-top:-6px" @click="formSearchActive = false">
                  <v-icon>fal fa-times-circle</v-icon>
                </v-btn>
              </template>
              <span>Cancel</span>
            </v-tooltip>
          </template>
        </v-autocomplete>
        <v-text-field v-else :value="formName" label="Form" readonly outlined>
          <template v-slot:prepend>
            <field-map :title="title" :field-map-source="fieldMap" :section="section" :revision-id="revisionId" @update="updateFieldMap"></field-map>
          </template>
          <template v-slot:append>
            <v-tooltip top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :disabled="!itemActive" icon style="margin-top:-6px" @click="formSearchActive = true">
                  <v-icon>fal fa-exchange</v-icon>
                </v-btn>
              </template>
              <span>Find a different form</span>
            </v-tooltip>
          </template>
          <template v-slot:append-outer>
            <v-tooltip v-if="form != null && form !== ''" top>
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" :to="'/forms/admin/' + form" icon style="margin-top:-6px">
                  <v-icon>fal fa-external-link</v-icon>
                </v-btn>
              </template>
              <span>View/Edit this form</span>
            </v-tooltip>
          </template>
        </v-text-field>
        <v-alert v-if="statusColor === 'error'" type="error">
          <v-row align="center">
            <v-col class="grow">
              <span v-if="statusText === 'Form Inactive'">The form is not currently active. Please click the Edit Form button to access the form to make sure it is active.</span>
              <span v-else-if="statusText === 'Field Mismatch'">Please review the field mapping, correlating the form inputs with the source Banner data, by clicking on the <v-icon small>fal fa-map-signs</v-icon> icon above.</span>
            </v-col>
            <v-col class="shrink">
              <v-btn v-if="statusText === 'Form Inactive'" :to="'/forms/admin/' + form">Edit Form</v-btn>
            </v-col>
          </v-row>
        </v-alert>
      </v-card-text>
      <v-card-actions>
        <v-btn :disabled="!changed" color="success" @click="save">Save</v-btn>
        <v-btn :disabled="!changed" color="error" @click="revert">Revert Changes</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="dialogActive = false">Close</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { ref, computed, watch } from '@vue/composition-api'
import { confirmationModel } from './data-layout'
export default {
  components: {
    FieldMap: () => import('./field-map.vue')
  },
  props: {
    checklistItem: {
      type: Object,
      required: true
    },
    itemIndex: {
      type: Number,
      required: true
    },
    checklist: {
      type: Array,
      required: true
    }
  },
  setup (props, { root, emit }) {
    const dialogActive = ref(false)
    const title = ref(props.checklistItem.title || '')
    const itemActive = ref(props.checklistItem.active || false)
    const detail = ref(props.checklistItem.detail || '')
    const classLevel = ref(props.checklistItem.classLevel || [])
    const studentType = ref(props.checklistItem.studentType || [])
    const form = ref(props.checklistItem.form || null)
    const formName = ref('')
    const statusText = ref('')
    const statusIcon = ref('')
    const statusColor = ref('')
    const fieldMap = ref(props.checklistItem.fieldMap || [])

    const formSearchActive = ref(false)
    const forms = ref([])
    const formSearch = ref('')
    watch(formSearch, () => {
      root.$feathers.service('forms/base').find({ query: { name: { $regex: formSearch.value || '', $options: 'i' }, $sort: { name: 1 } } }).then(({ data }) => {
        forms.value = data
      })
    })

    const prereqs = ref(props.checklistItem.prereqs || [])
    const prevItems = computed(() => {
      let arr = []
      for (let i = 0; i < props.itemIndex; i++) {
        if (props.checklist[i].active) arr.push(props.checklist[i].title)
      }
      return arr
    })
    const section = ref('')
    const revisionId = ref('')

    watch(form, () => {
      root.$feathers.service('forms/base').get(form.value).then((data) => {
        formName.value = data.name
        if (!itemActive.value) {
          statusText.value = 'Checklist Item Not Active'
          statusIcon.value = 'fal fa-times'
          statusColor.value = 'error'
        } else if (!('revisions' in data) || !('active' in data.revisions) || data.revisions.active == null || data.revisions.active === '') {
          statusText.value = 'Form Inactive'
          statusIcon.value = 'fal fa-exclamation-triangle'
          statusColor.value = 'error'
        } else {
          revisionId.value = data.revisions.active
          statusText.value = 'Form Active'
          statusIcon.value = 'fas fa-check'
          statusColor.value = 'success'
        }
        section.value = ''
        switch (form.value) {
          case '617ab1b5e2fe3e36dc195032':
            section.value = 'personal'
            break
          case '6171af60d3fbad35f06b61ef':
            section.value = 'contactInfo'
            break
          case '6171b0b1d3fbad35f06b6203':
            section.value = 'emergencyContacts'
            break
          case '617aae5fe2fe3e36dc19433e':
            section.value = 'academic'
            break
        }
        if (section.value !== '') {
          checkFieldMap(section.value)
        }
      })
    })

    function checkFieldMap (section) {
      for (let l in confirmationModel[section]) {
        if (typeof (confirmationModel[section][l]) === 'object') {
          if (Array.isArray(confirmationModel[section][l])) {
            if (typeof (confirmationModel[section][l][0]) === 'object') {
              // This is an array of objects (for a repeatable block)
              for (let k in confirmationModel[section][l][0]) {
                if (!checkFieldMapItem(section + '.' + l + '.' + k)) {
                  statusText.value = 'Field Mismatch'
                  statusIcon.value = 'fas fa-exclamation-triangle'
                  statusColor.value = 'error'
                  break
                }
              }
            } else {
              // This is an array of options (for a multiselect)
              if (!checkFieldMapItem(section + '.' + l)) {
                statusText.value = 'Field Mismatch'
                statusIcon.value = 'fas fa-exclamation-triangle'
                statusColor.value = 'error'
                break
              }
            }
          } else {
            for (let k in confirmationModel[section][l]) {
              if (!checkFieldMapItem(section + '.' + l + '.' + k)) {
                statusText.value = 'Field Mismatch'
                statusIcon.value = 'fas fa-exclamation-triangle'
                statusColor.value = 'error'
                break
              }
            }
          }
        } else {
          if (!checkFieldMapItem(section + '.' + l)) {
            statusText.value = 'Field Mismatch'
            statusIcon.value = 'fas fa-exclamation-triangle'
            statusColor.value = 'error'
            break
          }
        }
      }
    }

    function checkFieldMapItem (dataPath) {
      for (const { path } of fieldMap.value) {
        if (path === dataPath) return true
      }
      return false
    }
    const fieldMapChanged = ref(false)

    const changed = computed(() => {
      if (itemActive.value !== props.checklistItem.active ||
        title.value !== props.checklistItem.title ||
        detail.value !== props.checklistItem.detail ||
        classLevel.value.length !== props.checklistItem.classLevel.length ||
        studentType.value.length !== props.checklistItem.studentType.length ||
        (prereqs.value.length > 0 && (!('prereqs' in props.checklistItem) || prereqs.value.length !== props.checklistItem.prereqs.length)) ||
        fieldMapChanged.value ||
        form.value !== props.checklistItem.form) return true
      if (classLevel.value.length > 0) {
        const classSorted = classLevel.value.sort()
        const propClassSorted = props.checklistItem.classLevel.sort()
        for (let i = 0; i < classSorted.length; i++) {
          if (classSorted[i] !== propClassSorted[i]) return true
        }
      }
      if (studentType.value.length > 0) {
        const stypeSorted = studentType.value.sort()
        const propStypeSorted = props.checklistItem.studentType.sort()
        for (let i = 0; i < stypeSorted.length; i++) {
          if (stypeSorted[i] !== propStypeSorted[i]) return true
        }
      }
      if (prereqs.value.length > 0) {
        const prereqSorted = prereqs.value.sort()
        const propPrereqSorted = props.checklistItem.prereqs.sort()
        for (let i = 0; i < prereqSorted.length; i++) {
          if (prereqSorted[i] !== propPrereqSorted[i]) return true
        }
      } else if ('prereqs' in props.checklistItem && props.checklistItem.prereqs.length > 0) {
        return true
      }
      return false
    })

    const classLevels = ref([
      { text: 'Freshmen', value: 'FR' },
      { text: 'Sophomores', value: 'SO' },
      { text: 'Juniors', value: 'JR' },
      { text: 'Seniors', value: 'SR' },
      { text: 'Graduate First Time', value: 'GF' },
      { text: 'Graduate Continuing', value: 'GC' }
    ])

    const studentTypes = ref([
      { text: 'First-Time', value: 'F' },
      { text: 'Transfers', value: 'T' },
      { text: 'Returning', value: 'R' },
      { text: 'Continuing', value: 'C' },
      { text: 'Grad First-Time', value: 'G' },
      { text: 'Grad Continuing', value: 'D' }
    ])

    function revert () {
      if (confirm('Are you sure you want to revert your changes?')) {
        itemActive.value = props.checklistItem.active
        title.value = props.checklistItem.title
        detail.value = props.checklistItem.detail
        classLevel.value = props.checklistItem.classLevel
        studentType.value = props.checklistItem.studentType
        prereqs.value = props.checklistItem.prereqs
        form.value = props.checklistItem.form
        formSearchActive.value = false
      }
    }
    function save () {
      dialogActive.value = false
      emit('update', {
        active: itemActive.value,
        title: title.value,
        detail: detail.value,
        classLevel: classLevel.value,
        studentType: studentType.value,
        prereqs: prereqs.value,
        form: form.value,
        fieldMap: fieldMap.value
      })
    }

    function updateFieldMap ({ fieldMap: map }) {
      fieldMap.value = map
      fieldMapChanged.value = true
    }

    return {
      dialogActive,
      itemActive,
      title,
      detail,
      classLevel,
      studentType,
      form,
      formName,
      statusText,
      statusIcon,
      statusColor,
      fieldMap,
      formSearchActive,
      forms,
      formSearch,
      changed,
      classLevels,
      studentTypes,
      revert,
      save,
      prereqs,
      prevItems,
      section,
      revisionId,
      updateFieldMap
    }
  }
}
</script>
