exports.confirmationModel = {
  personal: {
    first: String,
    middle: String,
    last: String,
    legalName: String,
    preferred: String,
    gender: String,
    hispanic: String,
    ethnicity: String,
    citz: String,
    ssn: String,
    dob: Date,
    marital: String,
    spouse: {
      first: String,
      maiden: String,
      class: String
    }
  },
  academic: {
    advisor: String,
    major: String,
    minor: String,
    concentration: String,
    certification: String,
    classLevel: String,
    // classLevelCode: String,
    // studentType: String,
    expGradDate: String
  },
  contactInfo: {
    // housing: {
    //   room: String,
    //   building: String,
    //   hall: String
    // },
    address: [
      {
        code: String,
        // seqno: String,
        address: String,
        city: String,
        state: String,
        zip: String
      }
    ],
    email: [
      {
        email: String,
        preferred: Boolean
      }
    ],
    phone: [
      {
        phone: String,
        code: String,
        // seqno: String,
        preferred: Boolean
      }
    ]
  },
  emergencyContacts: {
    guardian: [
      {
        // pidm: String,
        firstName: String,
        lastName: String,
        relation: String,
        address: String,
        city: String,
        state: String,
        zip: String,
        // country: String,
        homePhone: String,
        cellPhone: String,
        email: String
      }
    ],
    ebill: [
      {
        ebill: String,
        comment: String
      }
    ],
    emergencyContact: [
      {
        firstName: String,
        lastName: String,
        phone: String,
        email: String
      }
    ],
    missingPersons: {
      firstName: String,
      lastName: String,
      phone: String
    },
    ferpa: {
      mayRelease: Boolean,
      releaseTo: String
    }
  }
}
